<template>
  <div id="app">
    <mainbody />
  </div>
</template>
<script>
import mainbody from './views/main.vue'
export default {
  components:{
    mainbody
  }
};
</script>
<style lang="less">
#app{
  height: 100%;
}
</style>
