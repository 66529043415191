<template>
  <div class="member">
    <el-card shadow="never">
      <h3>会员视图</h3>
      <div class="text_box">
        <p>
          上方的图表只是试意，具体以需求为准。上方只是为了说明，图表的类型由那些而已。
        </p>
        <p>
          基础数据包含：人群总数说明、男女比例、人群消费分布、人群访问分布、城市分布、会员类型、优惠券敏感度、月活跃度占比
        </p>
        <p>关于数据图表的刷新问题，当群组刷新后，图表的刷新跟着变</p>
      </div>
      <img class="img" src="@/assets/imgs/Rectangle 285.png" alt="" />
      <div class="btn_box">
        <p class="btn_box_p1">
          <span><i class="el-icon-back"></i> <span>上一章节</span></span>
          <span> <span>下一章节</span> <i class="el-icon-right"></i></span>
        </p>
        <p class="btn_box_p2">
          <span>会员视图</span>
          <span>营销视图</span>
        </p>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang='less'>
.member {
  padding: 20px ;
  h3 {
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #33393f;
    margin-bottom: 20px;
  }
  .text_box {
    width: 499px;
    height: 173px;
    p {
      margin-top: 15px;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }
  .img {
    width: 551px;
    height: 331px;
  }
  .btn_box {
    margin-top: 20px;
    width: 100%;
    .btn_box_p1 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #999999;
      align-items: center;
      > span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > span {
          margin-left: 5px;
          margin-right: 5px;
          font-size: 12px;
        }
      }
    }
    .btn_box_p2 {
      margin-top: 10px;
      padding: 0 10px;
      box-sizing: border-box;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
</style>