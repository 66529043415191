<template>
  <div class="memberCenter">
    <el-card  shadow="never">
      <h3>会员中台</h3>
      <p class="p" @click="$router.push({name:'detailPage'})">会员为什么无法正常导入？</p>
      <p class="p"  >为什么会员群组只有三个类型？</p>
      <p class="p">为什么渠道详情内的图表不能自定义时间？</p>
    </el-card>
   
  </div>
</template>

<script>
export default {
    data(){
        return{
            isShow:true
        }
    },
    methods: {
      
    },
 
};
</script>

<style lang='less'>
.memberCenter {
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  .el-card {
    min-height: 100%;
    h3 {
      font-size: 24px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #33393f;
    }
    .p {
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-top: 25px;
    }
  }
}
</style>