import useManual from '@/views/useManual/index'
import member from '@/views/useManual/member'
import marketing from '@/views/useManual/marketing'
import jimu from '@/views/useManual/jimu'
const router = [
    {
        path:'/',
        component:useManual,
        name:'useManual',
        redirect:'/member',
        children:[
            {
                path:'/',
                component:useManual,
                name:'useManual',
                redirect:'/member',
                meta:{
                    titleMenu:'数据概览规则'
                },
                children:[
                    {
                        path:'/member',
                        component:member,
                        name:'member',
                        meta:{
                            titleMenu:'会员视图'
                        }
                    },
                    {
                        path:'/marketing',
                        component:marketing,
                        name:'marketing',
                        meta:{
                            titleMenu:'营销视图'
                        }
                    },
                ]
            },
            {
                path:'/',
                component:useManual,
                name:'useManual',
                meta:{
                    titleMenu:'智能分析规则'
                },
                children:[
                    {
                        path:'/jimu',
                        component:jimu,
                        name:'jimu',
                        meta:{
                            titleMenu:'积木云助手'
                        }  
                    }
                ]
            }
        ]
    },
  
]
export default router