<template>
  <div class="topMenu">
    <div class="topMenu_title">
      <img src="@/assets/imgs/bi_logo.png" alt="" />
      <span class="s1">积木云客</span>
      <span class="s2">帮助中心</span>
    </div>
    <div class="topMenu_fnc">
      <el-input placeholder="请输入关键词"></el-input>
      <ul>
        <!-- <li>积木云官网</li> -->
        <li @click="$router.push({ name: 'memberCenter' })">常见问题</li>
        <li @click="$router.push({ name: 'member' })">使用手册</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>

<style lang='less'>
.topMenu {
  height: 68.92px;
  background-color: #327eff;
  position: relative;
  display: flex;
  .topMenu_title {
    height: 68.92px;
    width: 262px;
    // border-right: 1px solid #f5f7fb;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-right: 15px;
    box-sizing: border-box;
    img {
      width: 30px;
      height: 30px;
    }
    .s1 {
      font-size: 22px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      border-right: 2px solid #fff;
      line-height: 22px;
      padding-right: 10px;
    }
    .s2 {
      font-size: 18px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      // transform: translate(0px, 3px);
      line-height: 22px;
    }
  }
  .topMenu_fnc {
    height: 68.92px;
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-input {
      width: 220px;
      margin-left: 24px;
      .el-input__inner {
        width: 220px;
        height: 40px;
        line-height: 40px;
      }
    }
    ul {
      display: flex;
      li {
        margin-right: 50px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        &:last-child {
          margin-right: 24px;
        }
      }
    }
  }
}
</style>