import FAQ from '@/views/FAQ/index'
import memberCenter from '@/views/FAQ/memberCenter'
import memberAnalysis from '@/views/FAQ/memberAnalysis'
import marketingCenter from '@/views/FAQ/marketingCenter'
import marketingAnalysis from '@/views/FAQ/marketingAnalysis'

const router = [
    {
        path:'/FAQ',
        component:FAQ,
        name:'FAQ',
        redirect:'/FAQ/memberCenter',
        meta:{
            titleMenu:'工作台'
        },
        children:[
            {
                path:'/FAQ',
                component:FAQ,
                name:'FAQ',
                redirect:'/FAQ/memberCenter',
                meta:{
                    titleMenu:'工作台'
                },
                children:[
                    {
                        path:'/FAQ/memberCenter',
                        component:memberCenter,
                        name:'memberCenter',
                        meta:{
                            titleMenu:'会员中台'
                        },
                    },
                    {
                        path:'/FAQ/memberAnalysis',
                        component:memberAnalysis,
                        name:'memberAnalysis',
                        meta:{
                            titleMenu:'会员分析'
                        },
                    },
                    {
                        path:'/FAQ/marketingCenter',
                        component:marketingCenter,
                        name:'marketingCenter',
                        meta:{
                            titleMenu:'营销中台'
                        },
                    },
                    {
                        path:'/FAQ/marketingAnalysis',
                        component:marketingAnalysis,
                        name:'marketingAnalysis',
                        meta:{
                            titleMenu:'营销分析'
                        },
                    },
                ]
            }
        ]
    },
   
]
export default router