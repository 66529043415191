<template>
    <div class="useManual">
      <router-view />
    </div>
</template>

<script>
export default {

}
</script>

<style>
.useManual{

}
</style>