<template>
    <div class="FAQ">
        <router-view></router-view>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.FAQ{
  height: 100%;
}
</style>