<template>
  <div class="detail">
    <el-card shadow="never">
      <h3>会员为什么无法正常导入？</h3>
      <div class="text_box">
        <p>
          在摹客中，可通过插件在Figma、Sketch、PS和XD中进行切图并上传至摹客，一键下载全部切图。
        </p>
        <p>
         从Figma、Sketch、PS和XD中上传切图 通过插件，可以在Figma、Sketch、PS和XD中进行切图，并上传到摹客中。
        </p>
        <p>具体请看教程：</p>
      </div>
      <img class="img" src="@/assets/imgs/Rectangle 285 (1).png" alt="" />
      <div class="btn_box">
        <p class="btn_box_p1">
          <span><i class="el-icon-back"></i> <span>上一章节</span></span>
          <span> <span>下一章节</span> <i class="el-icon-right"></i></span>
        </p>
        <p class="btn_box_p2">
          <span>会员为什么无法正常导入？</span>
          <span>为什么渠道详情内的图表不能自定义时间</span>
        </p>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang='less'>
.detail {
    padding: 20px;
  h3 {
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #33393f;
    margin-bottom: 20px;
  }
  .text_box {
    width: 499px;
    height: 173px;
    p {
      margin-top: 15px;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }
  .img {
    width: 551px;
    height: 331px;
  }
  .btn_box {
    margin-top: 20px;
    width: 100%;
    border-top: 1px solid #EEEEEE;
    .btn_box_p1 {
        margin-top:10px ;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #999999;
      align-items: center;
      > span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > span {
          margin-left: 5px;
          margin-right: 5px;
          font-size: 12px;
        }
      }
    }
    .btn_box_p2 {
      margin-top: 10px;
      padding: 0 10px;
      box-sizing: border-box;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
</style>