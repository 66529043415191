<template>
  <div class="main">
      <top-menu />
     <div class="main_div">
      <left-menu  />
      <router-view></router-view>
     </div>
  </div>
</template>

<script>
import leftMenu from "./common/leftMenu.vue";
import topMenu from "./common/topMenu.vue";
export default {
    components:{
        leftMenu,
        topMenu,
    },
    data(){
      return{
      }
    }
}
</script>

<style lang='less'>
.main{
    height: 100%;
    display: flex;
    flex-direction:column;
    .layout{
      height: 100%;
    }
    .main_div{
      height: 100%;
      flex: 1;
      display: flex;
      >div{
        &:nth-child(2){
          flex: 1;
        }
      }
    }
}
</style>