<template>
  <div class="leftMenu">
    <div
      v-for="(i, index) in baseData[0].children"
      :key="index"
      class="leftMenu_box"
    >
      <p>{{ i.meta.titleMenu }}</p>
      <ul>
        <li
          :class="{ active: routerName == j.name }"
          v-for="(j, index1) in i.children"
          :key="index1"
          @click="pink(index, index1, j.name)"
        >
          {{ j.meta.titleMenu }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pick: 0,
      pick1: 0,
    };
  },
  computed: {
    baseData() {
      return this.$store.state.routeInfo;
    },
    routerName() {
      if (this.$route.meta.parentName) {
        return this.$route.meta.parentName;
      } else {
        return this.$route.name;
      }
    },
  },
  created() {},
  methods: {
    pink(n1, n2, name) {
      this.$router.push({ name: name });
    },
  },
};
</script>

<style lang='less'>
.leftMenu {
  width: 262px;
  height: 100%;
  background: #fff;
  // position: fixed;
  padding: 29px 36px 0 36px;
  box-sizing: border-box;
  .leftMenu_box {
    p {
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-bottom: 20px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-bottom: 20px;
        &:nth-child(2n) {
          margin-left: 60px;
        }
      }
    }
  }
  .active {
    color: #327eff !important;
  }
}
</style>