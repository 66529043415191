import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
import useManual from './useManual'
import FAQ from './FAQ'
import detailPage from '@/views/detailPage/index.vue'
const routes = [
  {
    path: '/detailPage',
    component: detailPage,
    name: 'detailPage',
    children: [
      {
        path: '/detailPage',
        component: detailPage,
        name: 'detailPage',
        meta: {
          titleMenu: 'detailPage',

        },
        children: [
          {
            path: '/detailPage',
            component: detailPage,
            name: 'detailPage',
            meta: {
              titleMenu: 'detailPage',
              parentName: 'memberCenter'
            }
          }
        ]
      }
    ]
  },
  ...useManual,
  ...FAQ

]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {

  next()
})
router.afterEach((to, from) => {
  // console.log('aaaa', router.options.routes);
  let currentName = router.currentRoute.matched[0].name
  let routerInfo = router.options.routes.filter(i => {
    if (i.name == currentName) {
      return i
    }
  })
  let parentName = routerInfo[0].children[0].children[0].meta.parentName
  if (parentName) {
    router.options.routes.forEach(i => {
      i.children.forEach(j => {
        j.children.forEach(k => {
          if (k.name == parentName) {
            routerInfo[0] = i
          }
        })
      })
    })
  }
  // console.log('routerInfo', routerInfo);
  store.commit('setRouteInfo', routerInfo)
})
export default router
